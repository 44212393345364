<template>
  <div class="field-group">
    <label :for="id" class="animated" v-bind:class="{ hasContent: this.value && this.value !== '' }">
      <span class="fixed-label">{{ labelText }}</span>
      <span class="animated-label">{{ labelText }}</span>
    </label>
    <div class="field-group" v-if="singleValue">
      <div class="field">
        <input
          type="text"
          :placeholder="labelText"
          v-model="stateValue"
          v-on:input="$emit('value-changed', $event.target.value)"
          :id="id"
          v-bind:class="{ 'form-field--error': !isvalid }"
        />
        <span class="required">*</span>
      </div>
    </div>
    <div class="select-wrapper" v-if="!singleValue" v-bind:class="{ 'form-field--error': !isvalid }">
      <select
        class="fill-width"
        v-if="!singleValue"
        v-model="stateValue"
        v-on:change="$emit('value-changed', $event.target.value)"
        ref="select"
        :id="id"
        v-bind:class="{ 'placeholder': !this.value, 'form-field--error': !isvalid }"
      >
        <option value="" selected>Select {{ labelText }}</option>
        <option v-for="state in theStates" :key="state.code" :value="state.code">{{ state.name }}</option>
      </select>
      <span class="required">*</span>
    </div>
  </div>
</template>
<script>
import { FormManagement } from '@/mixins/form-management'

export default {
  mixins: [FormManagement],
  model: {
    event: 'value-changed',
  },
  data() {
    return {
      stateValue: this.value,
      singleValue: false,
      states: [],
      caStates: [
        { code: 'AB', name: 'Alberta' },
        { code: 'BC', name: 'British Columbia' },
        { code: 'MB', name: 'Manitoba' },
        { code: 'NB', name: 'New Brunswick' },
        { code: 'NL', name: 'Newfoundland and Labrador' },
        { code: 'NS', name: 'Nova Scotia' },
        { code: 'ON', name: 'Ontario' },
        { code: 'PE', name: 'Prince Edward Island' },
        { code: 'QC', name: 'Quebec' },
        { code: 'SK', name: 'Saskatchewan' },
        { code: 'NT', name: 'Northwest Territories' },
        { code: 'NU', name: 'Nunavut' },
        { code: 'YT', name: 'Yukon' },
      ],
      auStates: [
        { code: 'ACT', name: 'Australian Capital Territory' },
        { code: 'NSW', name: 'New South Wales' },
        { code: 'NT', name: 'Northern Territory' },
        { code: 'QLD', name: 'Queensland' },
        { code: 'SA', name: 'South Australia' },
        { code: 'TAS', name: 'Tasmania' },
        { code: 'VIC', name: 'Victoria' },
        { code: 'WA', name: 'Western Australia' },
      ],
      usStates: [
        { code: 'AL', name: 'Alabama' },
        { code: 'AK', name: 'Alaska' },
        { code: 'AZ', name: 'Arizona' },
        { code: 'AR', name: 'Arkansas' },
        { code: 'CA', name: 'California' },
        { code: 'CO', name: 'Colorado' },
        { code: 'CT', name: 'Connecticut' },
        { code: 'DE', name: 'Delaware' },
        { code: 'DC', name: 'District Of Columbia' },
        { code: 'FL', name: 'Florida' },
        { code: 'GA', name: 'Georgia' },
        { code: 'HI', name: 'Hawaii' },
        { code: 'ID', name: 'Idaho' },
        { code: 'IL', name: 'Illinois' },
        { code: 'IN', name: 'Indiana' },
        { code: 'IA', name: 'Iowa' },
        { code: 'KS', name: 'Kansas' },
        { code: 'KY', name: 'Kentucky' },
        { code: 'LA', name: 'Louisiana' },
        { code: 'ME', name: 'Maine' },
        { code: 'MD', name: 'Maryland' },
        { code: 'MA', name: 'Massachusetts' },
        { code: 'MI', name: 'Michigan' },
        { code: 'MN', name: 'Minnesota' },
        { code: 'MS', name: 'Mississippi' },
        { code: 'MO', name: 'Missouri' },
        { code: 'MT', name: 'Montana' },
        { code: 'NE', name: 'Nebraska' },
        { code: 'NV', name: 'Nevada' },
        { code: 'NH', name: 'New Hampshire' },
        { code: 'NJ', name: 'New Jersey' },
        { code: 'NM', name: 'New Mexico' },
        { code: 'NY', name: 'New York' },
        { code: 'NC', name: 'North Carolina' },
        { code: 'ND', name: 'North Dakota' },
        { code: 'OH', name: 'Ohio' },
        { code: 'OK', name: 'Oklahoma' },
        { code: 'OR', name: 'Oregon' },
        { code: 'PA', name: 'Pennsylvania' },
        { code: 'RI', name: 'Rhode Island' },
        { code: 'SC', name: 'South Carolina' },
        { code: 'SD', name: 'South Dakota' },
        { code: 'TN', name: 'Tennessee' },
        { code: 'TX', name: 'Texas' },
        { code: 'UT', name: 'Utah' },
        { code: 'VT', name: 'Vermont' },
        { code: 'VA', name: 'Virginia' },
        { code: 'WA', name: 'Washington' },
        { code: 'WV', name: 'West Virginia' },
        { code: 'WI', name: 'Wisconsin' },
        { code: 'WY', name: 'Wyoming' },
      ],
    }
  },
  props: {
    country: { type: String, default: window.storeSettings.DefaultCountry },
    value: { type: String, default: '' },
    id: String,
    isvalid: Boolean,
  },
  computed: {
    labelText: function () {
      var self = this
      if (self.country === 'US' || self.country === 'AU') {
        self.$set(self, 'singleValue', false)
        return 'State'
      } else if (self.country === 'CA') {
        self.$set(self, 'singleValue', false)
        return 'Province'
      }

      self.$set(self, 'singleValue', true)

      return 'Region'
    },
    theStates: function () {
      var self = this

      if (self.country === 'US') {
        this.singleValue = false
        return self.usStates
      } else if (self.country === 'CA') {
        this.singleValue = false
        return self.caStates
      } else if (self.country === 'AU') {
        this.singleValue = false
        return self.auStates
      } else {
        self.$set(self, 'singleValue', true)
      }

      return []
    },
  },
}
</script>
