<template>
  <div class="card component-wrapper no-padding no-border no-top-padding no-shadow" v-if="billingAddress">
    <div>
      <div>
        <h2>Billing Address</h2>
        <form id="billing-form">
          <div class="field-group">
            <label for="billingAddress1" class="animated" :class="{ hasContent: billingAddress.Address1 }">
              <span class="fixed-label">Street Address</span>
              <span class="animated-label">Street Address</span>
            </label>
            <div class="field" style="display: block">
              <input
                class="fill-width"
                type="text"
                name="billingAddress1"
                id="billingAddress1"
                placeholder="Street and Number"
                v-model="billingAddress.Address1"
                :class="{ 'form-field--error': $v.billingAddress.Address1.$error }"
                @blur="$v.billingAddress.Address1.$touch()"
              />
              <span class="required">*</span>
            </div>
            <span
              data-valmsg-for="Address1"
              class="field-validation-error"
              :class="{ hidden: !$v.billingAddress.Address1.$error }"
            >
              <span for="billingAddress1" :class="{ hidden: $v.billingAddress.Address1.required }"
                >Address Line 1 is required</span
              >
            </span>
          </div>
          <div class="field-group">
            <div class="field" style="display: block">
              <input
                class="fill-width"
                type="text"
                name="billingAddress2"
                id="billingAddress2"
                placeholder="Apt / Suite / Building Nbr / etc"
                v-model="billingAddress.Address2"
                :class="{ 'form-field--error': $v.billingAddress.Address2.$error }"
              />
            </div>
          </div>
          <select-country
            v-model="billingAddress.Country"
            id="billingCountry"
            classes="fill-width"
            :isvalid="!$v.billingAddress.Country.$error"
          ></select-country>
          <div class="field-group inline" style="justify-content: flex-start">
            <div class="field-group" style="flex-grow: 1">
              <label for="billingCity" class="animated" :class="{ hasContent: billingAddress.City }">
                <span class="fixed-label">City</span>
                <span class="animated-label">City</span>
              </label>
              <div class="field" style="display: block">
                <input
                  type="text"
                  class="fill-width"
                  name="billingCity"
                  id="billingCity"
                  placeholder="City"
                  v-model="billingAddress.City"
                  :class="{ 'form-field--error': $v.billingAddress.City.$error }"
                  @blur="$v.billingAddress.City.$touch()"
                />
                <span class="required">*</span>
              </div>
            </div>
            <select-state
              :country="billingAddress.Country"
              v-model="billingAddress.State"
              id="billingState"
              :isvalid="!$v.billingAddress.State.$error"
            ></select-state>
            <div class="field-group">
              <label for="billingPostalCode" class="animated" :class="{ hasContent: billingAddress.ZipPostalCode }">
                <span class="fixed-label">Postal Code</span>
                <span class="animated-label">Postal Code</span>
              </label>
              <div class="field">
                <input
                  type="text"
                  class="fill-width"
                  name="billingPostalCode"
                  id="billingPostalCode"
                  placeholder="Postal Code"
                  @blur="$v.billingAddress.ZipPostalCode.$touch()"
                  v-model="billingAddress.ZipPostalCode"
                  :class="{ 'form-field--error': $v.billingAddress.ZipPostalCode.$error }"
                />
                <span class="required">*</span>
              </div>
            </div>
          </div>
          <span class="field-validation-error">
            <span for="billingCity" :class="{ hidden: !$v.billingAddress.City.$error }">City is required.</span>
            <span for="billingPostalCode" :class="{ hidden: !$v.billingAddress.ZipPostalCode.$error }"
              >Postal code is required.</span
            >
            <span for="billingState" :class="{ hidden: !$v.billingAddress.State.$error }"
              >State/Province is required.</span
            >
          </span>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SelectCountry from './select-country'
import SelectState from './select-state'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: { SelectCountry, SelectState },

  data() {
    return {
      billingAddress: this.defaultBillingAddress,
    }
  },

  computed: {
    isDirty() {
      return this.$v.$dirty
    },
    isValid() {
      return !this.$v.$invalid
    },

    ...mapGetters({
      labels: 'global/labels',
    }),
  },

  created() {
    this.notifyListeners()
  },
  mounted() {
    if (!this.billingAddress.Country || this.billingAddress.Country.length < 1) {
      if (this.billingAddress.Country.TwoLetterIsoCode) {
        this.billingAddress.Country = this.billingAddress.Country.TwoLetterIsoCode
      }
    }
  },

  methods: {
    notifyListeners() {
      window.dispatchEvent(
        new CustomEvent('billing-address:updated', {
          detail: {
            billingAddress: this.billingAddress,
            isValid: !this.$v.$invalid,
          },
        })
      )
    },
  },
  validations() {
    return {
      billingAddress: {
        Address1: {
          required,
        },
        Address2: {},
        City: {
          required,
        },
        Country: {
          required,
        },
        State: {
          requiredState() {
            if (this.billingAddress.Country.TwoLetterIsoCode) {
              return !(
                this.billingAddress.Country.TwoLetterIsoCode === 'US' ||
                this.billingAddress.Country.TwoLetterIsoCode === 'CA'
              )
            } else if (
              this.billingAddress.Country === 'USA' ||
              this.billingAddress.Country === 'US' ||
              this.billingAddress.Country === 'CA'
            ) {
              if (!this.billingAddress.State || this.billingAddress.State === '') {
                return false
              } else {
                return true
              }
            } else {
              return true
            }
          },
        },
        ZipPostalCode: {
          required,
        },
      },
    }
  },

  watch: {
    isDirty: {
      immediate: false,
      handler(toValue, fromValue) {
        if (toValue && !fromValue) {
          this.$emit('data-changed', 'billing-address')
        }
      },
    },
    isValid: {
      immediate: false,
      handler(toValue) {
        this.$emit('validation-changed', toValue)
        this.notifyListeners()
        if (toValue) {
          this.$emit('data-changed', 'billing-address')
        }
      },
    },
    validateHash: {
      immediate: false,
      handler(toValue) {
        if (toValue) {
          this.$v.$touch()
        }
      },
    },
  },

  props: {
    defaultBillingAddress: {
      type: Object,
      default: null,
    },
    validateHash: {
      type: String,
      default: null,
    },
  },
}
</script>
