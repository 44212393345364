// Globally register all base components for convenience.
// Components are registered using the PascalCased version of their file name.

// https://webpack.js.org/guides/dependency-management/#require-context

const init = (() => {
  const requireComponent = require.context(
    // The relative path of the components folder
    './components/global',
    // Look in subdirectories
    false,
    // Include any file ending in .vue
    /[\w-]+\.vue$/
  )

  const install = (Vue: any, options = {}) => {
    // For each matching file name...
    requireComponent.keys().forEach((fileName) => {
      // Get the component config
      const componentConfig = requireComponent(fileName)
      // Get the PascalCase version of the component name
      const componentName = fileName
        // Remove the "./" from the beginning
        .replace(/^\.\//, '')
        // Remove the file extension from the end
        .replace(/\.\w+$/, '')
        // Split up kebabs
        .split('-')
        // Upper case
        .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
        // Concatenated
        .join('')

      // Globally register the component
      Vue.component(componentName, componentConfig.default || componentConfig)
    })
  }

  // auto install
  if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
  } else {
    // Global view is not yet available - install based on event
    window.addEventListener('vue:loaded', () => {
      install(window.Vue)
    })
  }
})()
