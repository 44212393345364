<template>
  <div class="card component-wrapper no-padding no-shadow no-border no-top-padding">
    <div>
      <div>
        <h2>Phone Number</h2>
        <form id="phone-number-form" autocomplete="off">
          <div class="field-group">
            <div class="field" style="display: block">
              <masked-input
                key="prePopulatedNumber"
                name="tel1"
                mask="(111) 111 - 1111"
                placeholder="(555) 555 - 5555"
                type="tel"
                class="fill-width"
                :class="{ 'form-field--error': $v.phoneNumber.$error }"
                v-model="phoneNumber"
                @blur.native="onBlur"
                @input="onInput"
              />
              <span class="required">*</span>
            </div>
            <span
              data-valmsg-for="phoneNumber"
              class="field-validation-error"
              :class="{ hidden: !$v.phoneNumber.$error }"
            >
              <span for="phoneNumber" :class="{ hidden: $v.phoneNumber.required }">Phone Number is required</span>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import MaskedInput from 'vue-masked-input'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'phone-number',
  components: { MaskedInput },

  data() {
    return {
      phoneNumber: null,
    }
  },

  props: {
    prePopulatedNumber: {
      type: String,
      default: null,
    },
  },
  validations() {
    return {
      phoneNumber: {
        required,
      },
    }
  },
  created() {
    this.phoneNumber = this.prePopulatedNumber
    this.notifyListeners()
  },
  methods: {
    notifyListeners() {
      window.dispatchEvent(
        new CustomEvent('phone-number:updated', {
          detail: {
            phoneNumber: this.phoneNumber,
            isValid: !this.$v.phoneNumber.$invalid,
          },
        })
      )
    },
    onInput(formattedNum, unformattedNum) {
      this.$emit('input', unformattedNum)
      this.$v.phoneNumber.$touch()
      this.notifyListeners()
    },

    onBlur() {
      this.$v.phoneNumber.$touch()
      this.notifyListeners()
    },
  },

  computed: {
    isValid() {
      return !this.$v.phoneNumber.$invalid
    },
  },

  watch: {
    phoneNumber: function (val) {
      this.notifyListeners()
    },
    prePopulatedNumber: {
      immediate: false,
      handler(toValue) {
        this.phoneNumber = toValue
      },
    },
    isValid: {
      immediate: false,
      handler(toValue) {
        this.$emit('validation-changed', toValue)
      },
    },
  },
}
</script>
