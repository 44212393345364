<template>
  <div class="card-content has-tabs has-loading" v-if="show">
    <ul class="tab-radio-group">
      <li v-bind:class="{ active: selectedPaymentType === 'Affirm' }" v-on:click="openAffirm" data-tab="Affirm">
        <input
          type="radio"
          class="radio-button hidden"
          name="selectedPaymentType"
          value="Affirm"
          v-model="selectedPaymentType"
        />
        <div class="radio-tile"></div>
        <strong>{{ labels.Affirm.Payment.PanelText }}</strong>
        <div class="payment-icons">
          <img src="https://cdn-assets.affirm.com/images/black_logo-white_bg.svg" width="42px" height="17px" />
        </div>
      </li>
      <div class="add-card tab-sheet" v-show="selectedPaymentType === 'Affirm'">
        <p>{{ labels.Affirm.Payment.Text }}</p>
        <billing-address :default-billing-address="billingAddress" />
        <phone-number v-model="phoneNumber" :pre-populated-number="prePopulatedNumber" />
      </div>
      <li
        v-bind:class="{ active: selectedPaymentType === 'CreditCard' }"
        v-on:click="openHostedFields"
        data-tab="CreditCard"
      >
        <input
          type="radio"
          class="radio-button hidden"
          name="selectedPaymentType"
          value="CreditCard"
          v-model="selectedPaymentType"
        />
        <div class="radio-tile"></div>
        <strong>Credit Card</strong>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import AffirmPlugin from '../../libraries/affirm.plugin.js'
import BillingAddress from '../billing-address'
import PhoneNumber from '../phone-number'

Vue.use(AffirmPlugin, '$affirm')

export default {
  name: 'affirm-payment',
  components: { BillingAddress, PhoneNumber },
  data() {
    return {
      selectedPaymentType: 'CreditCard',
      initialPaymentInfo: null,
      phoneNumber: null,
      prePopulatedNumber: null,
      show: false,
      initialPaymentConfiguration: null,
      affirmPaymentConfiguration: {
        method: 'affirm',
        config: {
          RequiresBillingAddress: true,
          RequiresPhoneNumber: true,
          RequiresPayment: false,
        },
        AcceptedPaymentMethods: [],
        splitPayments: false,
      },
    }
  },
  async mounted() {
    this.show = await this.isAffirmEligible()
    this.initialPaymentInfo = this.paymentInfo
    this.prePopulatedNumber = this.personalization.Household[0].PrimaryPhone
    this.initialPaymentConfiguration = this.paymentConfiguration
  },
  methods: {
    async isAffirmEligible() {
      const url = '/api/affirm/cart'
      const response = await axios.get(url)
      return response.data == true
    },
    notifyListenersAffirmEnabled() {
      // create and dispatch the event
      window.dispatchEvent(
        new CustomEvent('payment:payment-configuration:updated', {
          detail: this.affirmPaymentConfiguration,
        })
      )
    },
    notifyListenersAffirmDisabled() {
      window.dispatchEvent(
        new CustomEvent('payment:payment-configuration:updated', {
          detail: null,
        })
      )
    },
    openAffirm: function (e) {
      this.notifyListenersAffirmEnabled()
      this.selectedPaymentType = 'Affirm'
      this.$store.commit('setPaymentInfo', {
        CardHolderName: null,
        CardType: null,
        CreditCard: null,
        ExpirationDate: null,
        Nounce: null,
        PaymentType: 'Payments.Affirm',
        IsAlternativePaymentMethod: true,
        ZipPostalCode: null,
      })
    },
    openHostedFields() {
      this.notifyListenersAffirmDisabled()
      this.selectedPaymentType = 'CreditCard'
      this.$store.commit('setPaymentInfo', this.initialPaymentInfo)
    },
  },
  computed: {
    acceptedPaymentMethods() {
      if (this.initialPaymentConfiguration && this.initialPaymentConfiguration.AcceptedPaymentMethods) {
        return this.initialPaymentConfiguration.AcceptedPaymentMethods.map((method) => {
          return method.toUpperCase()
        })
      }
      return []
    },
    ...mapGetters({
      personalization: 'personalization',
      paymentInfo: 'paymentInfo',
      billingAddress: 'billingAddress',
      labels: 'global/labels',
      paymentConfiguration: 'paymentConfiguration',
    }),
  },
  watch: {
    selectedPaymentType(toValue, fromValue) {
      this.$root.$emit('payment-type-selected', this.selectedPaymentType)
    },
  },
}
</script>

<style lang="scss">
.tab-radio-group > li {
  cursor: pointer;
}
</style>
