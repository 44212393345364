// TODO: Integrate this mixin in any components on this page that call hasContent global method above.
export const FormManagement = {
  methods: {
    fieldHasContent: function (fieldSelector) {
      var field = $(fieldSelector)
      return this.hasFormValue(field.val())
    },
    changeInput: function (e) {
      inputField = $(e.target)
      inputName = inputField.attr('name')
      if (inputField.val().length > 0) {
        label = $('label[for="]' + inputName + '"]')
      }
    },
    hasFormValue: function (value) {
      if (value) {
        return value.length > 0 && value !== '0'
      }

      return false
    },
    attributeControlId: function (attributeId) {
      return 'product_attribute_' + attributeId
    },
  },
}
