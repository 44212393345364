<template>
  <div class="affirm-confirm-container" v-if="isUsingAffirm">
    <div class="card component-wrapper no-padding no-border no-top-padding no-shadow">
      <div class="card-content no-top-padding">
        <div class="add-card no-top-padding text-align-center">
          <img class="affirm-logo-large" :src="affirmLogo" />
          <p>{{ labels.Affirm.Confirm.Text }}</p>

          <button
            class="affirm-button affirm-light-blue-background"
            @click="checkout"
            @mouseover="checkOutButtonImage = alternateAffirmLogo"
            @mouseleave="checkOutButtonImage = affirmLogo"
            :disabled="isProcessing"
          >
            <span v-if="!isProcessing"
              >{{ labels.Affirm.Confirm.CheckoutButtonText }}<img :src="checkOutButtonImage" />
            </span>
            <span v-else>Please Wait <img src="/Plugins/Payments.Affirm/dist/images/spinner.gif" /> </span>
          </button>
          <button class="affirm-button" @click="back" v-if="!isProcessing">
            {{ labels.Affirm.Confirm.BackButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AffirmPlugin from '../../libraries/affirm.plugin.js'
import { mapGetters, mapActions } from 'vuex'

Vue.use(AffirmPlugin, '$affirm')

export default {
  name: 'affirm-confirm',
  data: function () {
    return {
      affirmCheckoutToken: null,
      discounts: null,
      affirmLogo: 'https://cdn-assets.affirm.com/images/black_logo-transparent_bg.svg',
      alternateAffirmLogo: 'https://cdn-assets.affirm.com/images/white_logo-solid_bg.svg',
      checkOutButtonImage: 'https://cdn-assets.affirm.com/images/black_logo-transparent_bg.svg',
      isProcessing: false,
      affirmClientConfiguration: null,
    }
  },
  beforeMount() {
    if (this.isUsingAffirm) {
      this.$emit('addtionalPaymentSelected', 'affirm')
    }
    this.affirmClientConfiguration = this.personalization.ClientConfiguration.Features.filter(
      (c) => c.Name == 'Payments.Affirm'
    )[0]
  },
  methods: {
    setupCheckout() {
      this.$affirm.checkout(this.checkoutData)
    },
    back() {
      this.$router.push({ name: 'payment' })
    },
    checkout() {
      let self = this
      this.setupCheckout()
      this.$affirm.checkout.open({
        onFail: function (e) {
          self.back()
        },
        onSuccess: function (a) {
          self.isProcessing = true
          self.affirmCheckoutToken = a.checkout_token
          self.completeOrder()
        },
      })
    },
    completeOrder() {
      const startLoggingNow = this.loggingSettings.StartConfirmOrder

      this.logEvent({ event: 'confirm-order', startLogging: startLoggingNow })

      this.$store.dispatch('checkForExpiredItems').then((lockResult) => {
        if (this.hasExpiredItems) {
          this.$router.push({ name: 'expired items' })
        } else {
          let postData = {}
          if (this.personalization.PaymentInfo) {
            postData = {
              cardHolderName: '',
              creditCard: '',
              expirationDate: '',
              cvCode: '',
              cardType: '',
              zipPostalCode: this.personalization.BillingAddress.ZipPostalCode,
              savedPaymentMethodId: this.personalization.PaymentInfo.SavedPaymentMethodId,
              nonce: this.affirmCheckoutToken,
              deviceData: '',
              paymentType: 'Payments.Affirm',
              savePaymentInfo: false,
              resortChargePaymentInfo: undefined,
            }
          }

          this.$store.dispatch('resetOrderWarnings')

          this.$store
            .dispatch('completeOrder', postData)
            .then(() => {
              this.goToNextStep()
            })
            .finally(() => {
              this.continueProcessing = false
            })
        }
      })
    },
    goToNextStep() {
      this.$store.dispatch('setCompletedRoute', {
        routeName: this.$route.name,
        isComplete: true,
      })
      this.$store.dispatch('setCompletedRoute', {
        routeName: this.$route.name,
        isComplete: true,
      })
      this.$router.push({ name: 'complete' })
    },
    ...mapActions({
      logEvent: 'logging/logEvent',
      logMessage: 'logging/logMessage',
    }),
  },
  computed: {
    merchant() {
      return {
        user_cancel_url: 'https://www.affirm.com',
        user_confirmation_url: 'https://www.affirm.com',
        user_confirmation_url_action: 'GET',
      }
    },
    billing() {
      let currentUser = this.guestInfo.Household.find((u) => u.Me === true)

      return {
        name: {
          first: currentUser.FirstName,
          last: currentUser.LastName,
        },
        address: {
          line1: this.billingAddress.Address1,
          line2: this.billingAddress.Address2,
          city: this.billingAddress.City,
          state: this.billingAddress.State,
          country: this.billingAddress.Country,
          zipcode: this.billingAddress.ZipPostalCode,
        },
        email: currentUser.Email,
      }
    },
    shipping() {
      let currentUser = this.guestInfo.Household.find((u) => u.Me === true)

      let shippingAddress = this.personalization.ShippingAddress
      let useShippingAddress = shippingAddress && shippingAddress.AddressLine1

      return {
        name: {
          first: currentUser.FirstName,
          last: currentUser.LastName,
        },
        address: {
          line1: useShippingAddress ? shippingAddress.AddressLine1 : this.billingAddress.Address1,
          line2: useShippingAddress ? shippingAddress.AddressLine2 : this.billingAddress.Address2,
          city: useShippingAddress ? shippingAddress.City : this.billingAddress.City,
          state: useShippingAddress ? shippingAddress.State : this.billingAddress.State,
          country: useShippingAddress ? shippingAddress.Country : this.billingAddress.Country,
          zipcode: useShippingAddress ? shippingAddress.PostalCode : this.billingAddress.ZipPostalCode,
        },
        email: currentUser.Email,
      }
    },
    metadata() {
      let platformVersion = null
      let platformAffirm = null

      if (this.affirmClientConfiguration) {
        let platformVersion = this.affirmClientConfiguration.Settings.platform_version
        let platformAffirm = this.affirmClientConfiguration.Settings.platform_affirm
      }
      return {
        mode: 'modal',
        platform_type: 'Aspenware',
        platform_version: platformVersion,
        platform_affirm: platformAffirm,
      }
    },
    currency() {
      return 'USD'
    },
    total() {
      return Math.round(this.finalPaymentAmount * 100)
    },
    items() {
      let self = this
      let items = []
      let reservations = this.guestInfo.Reservations
      let baseUrl = window.location.hostname
      reservations.forEach(function (reservation) {
        items.push({
          sku: reservation.ProductId.toString(),
          display_name: reservation.ProductName,
          unit_price: Math.round(reservation.PriceValue * 100),
          qty: reservation.Quantity,
          item_url: baseUrl + '/p/' + reservation.ProductId,
          item_image_url: reservation.ProductImg,
        })

        if (reservation.Discounts.length > 0) {
          let dynamicJson = '{'
          reservation.Discounts.forEach(function (discount, index) {
            let discountAmount = Math.round(reservation.DiscountAmount * 100)
            dynamicJson =
              dynamicJson +
              '"' +
              discount.CouponCode +
              '":{ "discount_amount": ' +
              discountAmount.toString() +
              ', "discount_display_name": "' +
              discount.Name

            if (index != reservation.Discounts.length - 1) {
              dynamicJson = dynamicJson + '"},'
            } else {
              dynamicJson = dynamicJson + '"}'
            }
          })
          dynamicJson = dynamicJson + '}'

          self.discounts = JSON.parse(dynamicJson)
        }
      })
      return items
    },
    shippingAmount() {
      return Math.round(this.personalization.TotalDeliveryFees * 100)
    },
    taxAmount() {
      return Math.round(this.personalization.TotalTaxes * 100)
    },
    isUsingAffirm() {
      return this.paymentConfiguration.method == 'affirm'
    },
    checkoutData() {
      return {
        merchant: this.merchant,
        billing: this.billing,
        shipping: this.shipping,
        metadata: this.metadata,
        currency: this.currency,
        items: this.items,
        discounts: this.discounts,
        shipping_amount: this.shippingAmount,
        tax_amount: this.taxAmount,
        total: this.total,
      }
    },
    nextCheckoutRoute() {
      this.$store.dispatch('setCompletedRoute', {
        routeName: this.$route.name,
        isComplete: true,
      })
      this.$store.dispatch('setCompletedRoute', {
        routeName: this.$route.name,
        isComplete: true,
      })
      if (this.nextCheckoutRoute) {
        this.$router.push({ name: this.nextCheckoutRoute.name })
      }
    },
    ...mapGetters({
      personalization: 'personalization',
      billingAddress: 'billingAddress',
      finalPaymentAmount: 'finalPaymentAmount',
      guestInfo: 'guestInfo',
      loggingSettings: 'global/loggingSettings',
      labels: 'global/labels',
      paymentConfiguration: 'paymentConfiguration',
    }),
  },
}
</script>

<style lang="scss">
.affirm-confirm-container {
  margin-top: 20px;
}

.affirm-button {
  padding: 10px 20px 10px 20px;
  line-height: 38px;
  background-color: white;
  border: 2px solid black;
  cursor: pointer;
  font-weight: bold;
  margin-left: 7px;
  margin-top: 10px;
}

.affirm-button:disabled,
.affirm-button[disabled] {
  background-color: #cccccc;
  color: #666666;
  border-color: #666666;
}

.affirm-button:disabled img,
.affirm-button[disabled] img {
  vertical-align: middle;
  margin-left: 0px;
  height: 25px;
}

.affirm-button:disabled:hover,
.affirm-button[disabled]:hover {
  background-color: #cccccc;
  color: #666666;
}

.affirm-button:hover {
  background-color: black;
  color: white;
}

.affirm-light-blue-background {
  background-color: #e3eefc;
}

.affirm-button img {
  vertical-align: baseline;
  margin-left: -5px;
  height: 25px;
}

.affirm-logo-large {
  width: 275px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 10px;
}

// Common
.text-align-center {
  text-align: center;
}

.payment-info {
  margin: 0px;
}
</style>
