<template>
  <div class="affirm-promotional-message card-content list-content no-padding" v-show="show && externalPluginShow">
    <p
      class="affirm-as-low-as"
      v-bind:data-amount="loanAmountInCents"
      v-bind:data-page-type="pageType"
      :data-affirm-type="'logo'"
      :data-affirm-color="'blue'"
      v-if="loanAmountInCents && pageType"
    ></p>
  </div>
</template>

<script>
import axios from 'axios'
import AffirmPlugin from '../../libraries/affirm.plugin.js'
import { mapGetters } from 'vuex'

Vue.use(AffirmPlugin, '$affirm')

const pageTypes = {
  product: 'product',
  cart: 'cart',
  payment: 'payment',
}

export default {
  name: 'affirm-promotional-message',
  data: function () {
    return {
      show: false,
      externalPluginShow: true,
    }
  },
  props: {
    pageType: {
      type: String,
      default: pageTypes.cart,
      validator: (value) => {
        return Object.values(pageTypes).indexOf(value) > -1
      },
      required: false,
    },
  },
  mounted() {
    this.refresh()
  },
  created() {
    window.addEventListener('affirm:promotional-message:display', this.onPromotionalMessageDisplay)
    window.addEventListener('product:attributes:updated', this.onProductAttributeChanged)
  },
  destroyed() {
    window.removeEventListener('affirm:promotional-message:display', this.onPromotionalMessageDisplay)
    window.removeEventListener('product:attributes:updated', this.onProductAttributeChanged)
  },
  methods: {
    async isAffirmEligible() {
      // Check Product
      let result = true
      if (this.pageType === pageTypes.product) {
        let selectedProduct = this.$store.getters['products/selectedProduct']

        let selectedProductAttributes = selectedProduct.ProductAttributes
        var mappedAttributes = selectedProductAttributes
          .filter((pa) => pa.SelectedValueId !== null)
          .map((pa) => ({ Id: pa.Id, SelectedValueId: pa.SelectedValueId }))

        const url = '/api/affirm/product/' + selectedProduct.Id
        const response = await axios.post(url, mappedAttributes)
        result = response.data
      }

      // Check Cart And Payment
      if (this.pageType === pageTypes.cart || this.pageType === pageTypes.payment) {
        const url = '/api/affirm/cart'
        const response = await axios.get(url)
        result = response.data

        if (
          this.configuration &&
          this.configuration.method == 'affirm' &&
          this.pageType === pageTypes.payment &&
          result == true
        ) {
          return true
        }
      }

      return result
    },
    async refresh() {
      const isConfirmPage = window.location.href.indexOf('/confirm') != -1
      this.show = (await this.isAffirmEligible()) && !isConfirmPage
      if (this.show) {
        this.$affirm.ui.refresh()
      }
    },
    async onPromotionalMessageDisplay(evnt) {
      if (evnt.detail.type == this.pageType) {
        this.externalPluginShow = event.detail.show
      }
    },
    async onProductAttributeChanged() {
      if (this.pageType === pageTypes.product) {
        await this.refresh()
      }
    },
  },
  computed: {
    configuration() {
      return this.paymentConfiguration || null
    },
    total() {
      if (this.pageType === pageTypes.product) {
        let price = this.$store.getters['products/selectedProduct'].ProductPrice.Price
        if (price) {
          let total = Number(price.replace(/[^0-9.-]+/g, ''))
          return total
        }
      } else if (this.pageType === pageTypes.cart) {
        return this.$store.getters['cart/cart'].TotalPriceAmount
      } else if (this.pageType === pageTypes.payment) {
        return this.$store.getters['finalPaymentAmount']
      } else {
        return null
      }
    },
    loanAmountInCents() {
      if (this.total) {
        return this.total * 100
      }
      return null
    },
    ...mapGetters({
      paymentConfiguration: 'paymentConfiguration',
    }),
  },
  watch: {
    loanAmountInCents: function (val, oldVal) {
      if (this.pageType !== pageTypes.product && val && val !== oldVal) {
        this.refresh()
      }
    },
  },
}
</script>

<style lang="scss">
.affirm-promotional-message {
  margin-top: 10px;
  text-align: center;
}
</style>
